<template>
  <form
    ref="form"
    @submit.prevent="sendDateProchainCongres"
  >
    <div v-if="isUserDepartemental">
      <v-alert 
        type="error"
      >
        Vos droits ont été bloqués, vous devez impérativement saisir votre date de prochain congrès pour débloquer l'application.
      </v-alert>
      <p>
        Veuillez renseigner la date de votre prochain congrès :  
      </p>
      <DatePicker
        label="Date de prochain congrès"
        :date="dateProchainCongres"
        :readonly="true"
        :required="true"
        :between="{ after: now, before: limitDateProchainCongres, inclusion: '[]'}"
        @changeDate="changeCongresDate"
      />
 
      <v-btn
        type="submit"
        :disabled="!formValid"
        color="primary"
        :loading="saving"
      >
        Enregistrer
        <v-icon
          right
          dark
        >
          edit
        </v-icon>
      </v-btn>
    </div>
    <div v-else>
      <v-alert 
        v-if="user.bloque"
        type="error"
      >
        Votre accès pour l'année scolaire {{ anneeScolaireCourante ? anneeScolaireCourante.libelle : 'en cours' }} n'a pas encore été renouvelé, veuillez contacter votre CDPE :
      </v-alert>
      <v-alert 
        v-else
        type="error"
      >
        Votre CDPE n'a pas renseigné sa date de prochain congrès, tous ses accès sont bloqués, veuillez contacter votre CDPE :
      </v-alert>
      <v-row>
        <v-col>
          <v-text-field
            label="CDPE"
            prepend-icon="home"
            :value="`${cdpe.code} ${cdpe.nom}`"
            :readonly="true"
          />
        </v-col>  
      </v-row>    
      <v-row>
        <v-col>
          <v-btn
            v-if="cdpe.contact.email"
            :href="`mailto:${cdpe.contact.email}`"
            small
          >
            <v-icon
              small
              left
            >
              email
            </v-icon>{{ cdpe.contact.email }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            v-if="cdpe.contact.telephone_fixe"
            :href="`tel:${removeDots(cdpe.contact.telephone_fixe)}`"
            small
          >
            <v-icon
              small
              left
            >
              phone
            </v-icon>{{ cdpe.contact.telephone_fixe }}
          </v-btn>
        </v-col>    
        <v-col>
          <v-btn
            v-if="cdpe.contact.telephone_mobile"
            :href="`tel:${removeDots(cdpe.contact.telephone_mobile)}`"
            small
          >
            <v-icon
              small
              left
            >
              smartphone
            </v-icon>{{ cdpe.contact.telephone_mobile }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <br>
        </v-col>
      </v-row>
    </div>
  </form>
</template>
<script>
import DatePicker from '../partials/DatePicker';
import { removeDots } from '../../config/helpers';

export default {
  name: 'BlocageForm',
  components: {
    DatePicker,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    isUserDepartemental: {
      type: Boolean,
      default: false,
    },
    cdpe: {
      type: Object,
      default: () => {},
    },
    anneeScolaireCourante: {
      type: Object,
      default: () => {},
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dateProchainCongres: '',
  }),
  computed: {
    formValid() {
      // loop over all contents of the fields object and check if they exist and valid.
      return Object.keys(this.fields).every(field => {
        return this.fields[field] && this.fields[field].valid;
      });
    },
    now() {
      return (new Date()).toISOString().replace(/T.*/, '');
    },
    limitDateProchainCongres() {
      if (!this.anneeScolaireCourante || !this.anneeScolaireCourante.date_fin) {
        return '';
      }
      let d = new Date(this.anneeScolaireCourante.date_fin);
      d.setFullYear(d.getFullYear() + 1);
      return d.toISOString().replace(/T.*/, '');
    },
  },
  methods: {
    changeCongresDate(date) {
      this.dateProchainCongres = date;
    },
    sendDateProchainCongres() {
      this.$emit('submit', this.dateProchainCongres);
    },
    removeDots(value) {
      return removeDots(value);
    },
  },
};
</script>