<template>
  <BlocageForm 
    :user="user"
    :is-user-departemental="isUserDepartemental"
    :cdpe="cdpe"
    :annee-scolaire-courante="anneeScolaireCourante"
    :saving="saving"
    @submit="onSubmit"
  />
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import BlocageForm from '../../components/cdpe/Blocage';
import types from '../../actions/types';


export default {
  name: 'BlocageContainer',

  components: {
    BlocageForm,
  },

  data: () => ({
  }),

  computed: {
    ...mapGetters('session', {
      user: 'getUser',
      userCdpeId: 'getUserCdpeId',
      isUserDepartemental: 'isUserDepartemental',
    }),
    ...mapGetters('parametrages/anneeScolaire', {
      anneeScolaireCourante: 'getAnneeScolaireCourante',
    }),
    ...mapState('cdpe/crud', [
      'cdpe',
      'saving',
    ]),
  },
  created() {

    this.loadCdpeById({ cdpeId: this.userCdpeId }).then(response => {
      this.$store.commit('cdpe/crud/setCurrentCdpe', response.data);
    });
    this.loadFilterAnneesScolaires();

  },

  methods: {
    ...mapActions('cdpe', {
      loadCdpeById: 'loadById',
    }),
    ...mapActions('cdpe/crud', {
      saveCdpe: 'save',
    }),
    ...mapActions('parametrages/anneeScolaire', [
      'loadFilterAnneesScolaires',
    ]),
    onSubmit(date) {
      let cdpe = {
        adresse_legale: {
          ligne1: this.cdpe.adresse_legale.ligne1,
          ligne2: this.cdpe.adresse_legale.ligne2,
          commune_id: this.cdpe.adresse_legale.commune.id,
        },
        commentaire: this.cdpe.commentaire,
        contact: {
          email: this.cdpe.contact.email,
          telephone_fixe: this.cdpe.contact.telephone_fixe,
          telephone_mobile: this.cdpe.contact.telephone_mobile,
        },
        date_prochain_congres: date,
        horaires: this.cdpe.horaires,
        nom: this.cdpe.nom,
        numero_urgence: this.cdpe.numero_urgence,
        secretaire: this.cdpe.secretaire,
        site_web: this.cdpe.site_web,
      };
      this.saveCdpe({
        cdpe, 
        cdpeId: this.cdpe.id,
      }).then(() => {
        this.$store.commit('session/' + types.SESSION_SET_BLOQUE, {});
        this.$router.push({ name: 'recherche-parents' });
      });
    },
  },
};
</script>